import React, { useState } from "react";
import axios from "axios";
import "./App.css";

function App() {
  const [name, setName] = useState("");
  const [job, setJob] = useState("");
  const [experience, setExperience] = useState("");
  const [landingPageUrl, setLandingPageUrl] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    const userData = { name, job, experience };

    try {
      const response = await axios.post("http://highteequeen.id.vn:9080/api/users/create", userData);
      setLandingPageUrl(response.data);
    } catch (error) {
      console.error("Error creating landing page:", error);
    }
  };

  return (
    <div className="App">
      <header className="App-header">
        <h1>Landing Page Generator</h1>
        <form onSubmit={handleSubmit}>
          <div>
            <label>Name:</label>
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </div>
          <div>
            <label>Job:</label>
            <input
              type="text"
              value={job}
              onChange={(e) => setJob(e.target.value)}
              required
            />
          </div>
          <div>
            <label>Experience:</label>
            <input
              type="text"
              value={experience}
              onChange={(e) => setExperience(e.target.value)}
              required
            />
          </div>
          <button type="submit">Create Landing Page</button>
        </form>

        {landingPageUrl && (
          <div>
            <h2>Your Landing Page:</h2>
            <a href={`http://${landingPageUrl}`} target="_blank" rel="noopener noreferrer">
              {landingPageUrl}
            </a>
          </div>
        )}
      </header>
    </div>
  );
}

export default App;
